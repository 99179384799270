/* istanbul ignore file */

/*
 * Encapsulates sending HTTP requests.
 * If we later switch from using Axios, only this file should be updated.
 */

import axios, { AxiosRequestConfig } from 'axios';

export type HttpRequest<T> = {
  url: string;
  acceptHeader: string;
  authorizationHeader?: string;
  contentTypeHeader: string;
  data?: T;
  withCredentials: boolean;
};

export function parseStatusCodeFromAxiosErrorMessage(
  message: string,
): number | null {
  // When an HTTP request returns an error response code,
  // Axios throws an error with a message like "Failed with status code 400"
  const statusCodeRegex = /status code (\d+)/;
  const match = statusCodeRegex.exec(message);
  const statusCode = match?.[1];
  return statusCode ? Number(statusCode) : null;
}

export class HttpError extends Error {
  public statusCode: number | null;

  constructor(message: string) {
    super(message);
    this.statusCode = parseStatusCodeFromAxiosErrorMessage(message);
  }
}

export async function post<TRequest, TResponse>(
  request: HttpRequest<TRequest>,
): Promise<TResponse> {
  const config: AxiosRequestConfig = {
    withCredentials: request.withCredentials,
    timeout: 5000, // milliseconds
    headers: {
      Accept: request.acceptHeader,
      Authorization: request.authorizationHeader,
      'Content-Type': request.contentTypeHeader,
    },
  };

  try {
    const response = await axios.post<TResponse>(
      request.url,
      request.data,
      config,
    );

    return response.data;
  } catch (e) {
    throw new HttpError(e.message);
  }
}
